/** @file Integra el chat de Fresh en la aplicación */

import onmount from 'onmount';

async function initFreshChat() {
  const userInfoElement = document.getElementById('fresh-desk-user-info');
  if (!userInfoElement?.innerText?.trim()) {
    return;
  }

  const userData = JSON.parse(userInfoElement.innerText);
  if (!userData) {
    return;
  }

  const restoreId = userData.restoreId;
  window.fcWidget.init({
    token: userData.token,
    host: 'https://wchat.freshchat.com',
    externalId: userData.email,
    restoreId: restoreId || null,
    firstName: userData.first_name,
    lastName: userData.last_name,
    email: userData.email,
    siteId: userData.host,
    config: {
      content: {
        placeholders: {
          csat_reply: 'Deja tus comentarios aquí',
        },
        actions: {
          csat_yes: 'Calificar Agente',
          csat_no: 'Continuar Chat',
        },
        headers: {
          csat_rate_here: 'Califica tu experiencia',
          csat_thankyou: '¡Gracias! seguiremos revisando',
          csat_question: '¿Algo más en lo que podamos ayudarte?',
          csat_yes_question: 'Por favor, evalúa la calidad de servicio y resolución del agente',
          // eslint-disable-next-line max-len
          csat_no_question: 'Por favor, nos entregas mayor información para seguir analizando',
        },
      },
    },
  });

  window.fcWidget.user.setProperties({
    'Cliente': userData.customer,
    'Tenant': userData.tenant,
    'Perfil_de_usuario': userData.permission_role,
    'Pais': userData.country,
    'Ciudad': userData.city,
    'Ultima_conexion_aplicacion': userData.last_connection,
    'Celular': userData.cell_phone,
    'Telefono_trabajo': userData.office_phone,
    'Edad': userData.age,
    'Cumpleanos': userData.birth_date_at,
    'Es_Superadmin': userData.is_superadmin,
    'Cliente_tiene_css_customizado': userData.company_has_custom_css,
    'Rol_empleados': userData.employees_permission_role,
    'Rol_configuracion_sistema': userData.system_configuration_permission_role,
    'Limite_areas': userData.limit_areas,
    'Limite_juego_de_datos': userData.limit_realms,
    'Es_normal': userData.normal_user,
    'Cantidad_de_colaboradores': userData.quantity_of_employees,
  });
  window.fcWidget.on('widget:loaded', function () {
    window.fcWidget.on('user:created', async function () {
      if(!restoreId || restoreId === 'null' || restoreId === 'undefined') {
        const user = await window.fcWidget.user.get();
        $.ajax({
          method: 'POST',
          dataType: 'json',
          url: '/users/save_fresh_restore_id',
          data: { restoreId: user.data.restoreId },
        });
      }
    });
  });
}

function initialize(document, id) {
  const freshDeskInfo = document.getElementById('fresh-desk-user-info');
  if (!freshDeskInfo?.innerText?.trim()) {
    return;
  }

  if (document.getElementById(id)) {
    initFreshChat();
  }
  else {
    const script = document.createElement('script');
    script.id = id;
    script.async = true;
    script.src = 'https://wchat.freshchat.com/js/widget.js';
    script.onload = initFreshChat;
    document.head.appendChild(script);
  }

}

function initiateCall() {
  if (!document.body.dataset.enableFresh) {
    return;
  }
  initialize(document, 'freshchat-js-sdk');
}
onmount('body[data-enable-fresh=true]', initiateCall);
$(document).on('turbolinks:before-visit', function () {
  window.fcWidget?.destroy();
});

// verifica cada 100ms si el widget de freshchat ya está cargado hasta un máximo de 5 segundos
document.addEventListener('turbolinks:load', function () {
  if (!document.body.dataset.enableFresh) {
    return;
  }

  const intervalId = setInterval(function () {
    const freshWidget = document.querySelector('.fc-widget-normal');
    if (freshWidget) {
      const IsIntercomActive = document.getElementById('communication_employee_service');
      if (!IsIntercomActive) {
        $(freshWidget).addClass('only-freshchat');
      }
      clearInterval(intervalId);
    }
  }, 100);

  // Detener la búsqueda después de 5 segundos si no se encuentra el elemento
  setTimeout(function () {
    clearInterval(intervalId);
  }, 5000);
});
