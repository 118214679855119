/**
 * @file Archivo principal para el manejo de módulos de upselling.
 * Este archivo contiene funciones para solicitar módulos de upselling, manejar eventos de clic y realizar marcaciones en Amplitude.
 * También incluye funciones de utilidad para realizar solicitudes AJAX y obtener datos relacionados con los módulos de upselling.
 */
import onmount from 'onmount';
import { once } from 'lodash-es';

function doMarkAmplitude(element, message, data) {
  element.trigger('amplitudeTracking',
    {
      message: message,
      data: data,
    });
}

/**
 * Marcaciones para la nueva estructura Landing
 */

onmount('#upselling-box', () => {
  createAmplitudeMarkerOnClickLandingButtons();
  createAmplitudeMarkerOnVideo();
  $('.demo_video_upselling').each(function () {
    $(this).on('click', (event) => {
      event.preventDefault();
      document
        .getElementById('video_container')
        .scrollIntoView({ behavior: 'smooth' });
    });
  });
});

// Marcación amplitude para botones en landing
function createAmplitudeMarkerOnClickLandingButtons() {
  const upsellingIndexButtons = $('#upselling-box').find('.amplitude_upselling');

  upsellingIndexButtons.each(function () {
    const amplitudeInfo = getAmplitudeData($(this));
    $(this).on('click', () => {
      doMarkAmplitude($(this), amplitudeInfo.event, amplitudeInfo.data);
    });
  });
}

//Maneja el evento de clic en el video y agrega un marcador de Amplitude.
function createAmplitudeMarkerOnVideo() {
  let firstPlayClick = true;
  const videoModule = $('#video-upselling');
  const event = videoModule[0].dataset.event;
  const data = {
    module_name: videoModule[0].dataset.name,
    source: videoModule[0].dataset.source,
    video_type: videoModule[0].dataset.type,
  };

  // Agregar el event listener para el evento 'play'
  videoModule.on('play', function () {
    if (firstPlayClick) {
      doMarkAmplitude(
        videoModule,
        event,
        data
      );
      firstPlayClick = false;
    }
  });
}

function getAmplitudeData(element) {
  const data = element.data();
  return { event: data.amplitudeEvent, data: data.amplitudeData };
}

//Integracion hubspot
const addHubspot = once(function addHubspot() {
  const script = document.createElement('script');
  script.id = 'upselling-hubspot-script';
  script.type = 'text/javascript';
  script.defer = true;
  script.async = true;
  script.src = 'https://static.hsappstatic.net/MeetingsEmbed/ex/MeetingsEmbedCode.js';
  document.head.appendChild(script);
});

function checkHubspotLoaded() {
  return new Promise((resolve, _) => {
    const checkScriptHub = () => {
      if (typeof window.hbspt === 'undefined') {
        setTimeout(checkScriptHub, 1000);
      }
      else {
        resolve();
      }
    };
    checkScriptHub();
  });
}

onmount('.hubspot-form-embed', () => {
  checkHubspotLoaded().then(() => {
    window.hbspt.meetings.create('#hubspot-form-embed');
  });
});

// Integracion Calendly
const addCalendly = once(function addCalendly() {
  const script = document.createElement('script');
  script.id = 'upselling-calendly-script';
  script.src = 'https://assets.calendly.com/assets/external/widget.js';
  script.defer = true;
  script.async = true;
  document.head.appendChild(script);
});

function checkCalendlyLoaded() {
  return new Promise((resolve, _) => {
    const checkScript = () => {
      if (typeof Calendly === 'undefined') {
        setTimeout(checkScript, 1000);
      }
      else {
        resolve();
      }
    };
    checkScript();
  });
}

onmount('#calendly-embed', () => {
  checkCalendlyLoaded().then(() => {
    const form = document.getElementById('calendly-embed');
    /* global Calendly */
    Calendly.initInlineWidget({
      url: form.dataset.src,
      parentElement: form,
      prefill: {
        firstName: form.dataset.firstname,
        lastName: form.dataset.lastname,
        email: form.dataset.email,
        customAnswers: {
          a1: form.dataset.company,
          a2: form.dataset.employee,
          a3: form.dataset.module,
        },
      },
    });
  });
});

// Cargar paquetes javascript
const windowLoad = $.Deferred();
$(window).on('load', () => windowLoad.resolve());

// Ajusta la vista cuando se muestra el banner de beneficios
onmount('#upselling-banner-banner_beneficios_1', function () {
  const targetElement = document.querySelector('.content-wrapper');
  targetElement.scrollTo({ top: 0,  behavior: 'smooth' });
});

onmount('body', function () {
  windowLoad.then(() => {
    addCalendly();
    addHubspot();
  });
});

// Al cerrar banner de cross-selling, crear registro en AbrahamHistory
onmount('.close-cross-selling-banner', () => {
  $('.close-cross-selling-banner').off('click').on('click', async function () {
    const { authenticityToken, tourName, hideBanner, amplitudeTracking } = this.dataset;
    const amplitudeData = JSON.parse(amplitudeTracking);

    doMarkAmplitude($(this), amplitudeData.message, amplitudeData.data);

    if (hideBanner === 'true') { $(`#upselling-banner-${tourName}`).hide(); }

    await fetch('/abraham_histories/', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        authenticity_token: authenticityToken, tour_name: tourName, record_type: 'banner',
      }),
    });
  });
});

onmount('[data-amplitude-mark-component-view]', () => {
  const banner = $('[data-amplitude-mark-component-view]');
  const amplitudeData = banner.data().amplitudeTracking;

  // Nos aseguramos que amplitude esté inicializado para gatillar la marca
  if (window.amplitude) {
    doMarkAmplitude(banner, amplitudeData.message, amplitudeData.data);
  }
  else {
    $(document).on('amplitude_init', () => doMarkAmplitude(banner, amplitudeData.message, amplitudeData.data));
  }
});
