/** @file Comportamientos del formulario de creación / edición del servicio accounting_structure */
import onmount from 'onmount';
import { actionMessage } from '../lib/buk/alert';
onmount('.import-formula', function () {
  document.addEventListener('click', function (event) {
    const button = event.target.closest('button[type="button"]');
    if (!button || button.id !== 'formula-button') return;
    const formulaField = button.dataset.formulaFieldValue;
    const monacoEditorFormula = document.getElementById(`formula_${formulaField}`);
    const textArea = monacoEditorFormula.querySelector('editor-formulas-accounting').firstChild;
    if (textArea.nodeType === Node.ELEMENT_NODE && textArea.tagName === 'TEXTAREA') {
      const formulaVal = textArea.value;
      actionMessage('Importar fórmula', `¿Deseas importar la fórmula ${formulaVal}?`, 'Aceptar', 'Cancelar')
        .then(result => {
          if(result.isConfirmed) {
            const field = `#input-formula-${formulaField}`;
            $(field).attr('value', formulaVal);
          }
        });
    }
  });
});
