
/** @file
 * Controla los eventos del formulario new_tipo_atributo_custom
 * en la pantalla de 'Atributos Personalizados'
 */
import onmount from 'onmount';
import Intercooler from '../../../../../../../../app/assets/javascripts/lib/intercooler.js';

const InputSizeValues = {
  corto: 'corto',
  mediano: 'mediano',
  largo: 'largo',
  xl: 'xl',
};
Object.freeze(InputSizeValues);

const enableASingleOptionFromASelect = function (selectInput, valueToSelect) {
  const defaultValue = $('option[value="' + valueToSelect + '"]', selectInput).val();
  $('option[value!="' + valueToSelect + '"]', selectInput).attr('disabled', true).hide();
  selectInput.val(defaultValue);
};

const enableAllOptionsFromASelect = function (selectInput, valueToSelect) {
  $('option', selectInput).attr('disabled', false).show();
  const defaultValue = $(`option[value='${valueToSelect}']`, selectInput).val();
  selectInput.val(defaultValue);
};

const toLockSelectSizeOptions = (selectInput) => {
  enableASingleOptionFromASelect(selectInput, InputSizeValues.mediano);
};

const toUnlockSelectSizeOptions = (selectInput) => {
  enableAllOptionsFromASelect(selectInput, InputSizeValues.corto);
};

onmount('#new_tipo_atributo_custom, #edit_tipo_atributo_custom', function () {
  const valueType = $('#tipo_atributo_custom_tipo_valor');
  const valueDiv = $('#default-value-div');
  const valueSelectDiv = $('#select-default-value-div');
  const entityType = $('#tipo_atributo_custom_entidad');
  const valueSelect = $('#select-default-value');
  const mandatoryValue = $('#tipo_atributo_custom_obligatorio');
  const mandatoryInputsDiv = $('#contenedor_obligatorio');
  const optionsSelect = $('#tipo_atributo_custom_opciones');
  const selectInputSize = $('#tipo_atributo_custom_input_size');
  if (valueType.val() === 'lista') {
    const inputField = document.querySelector('#tipo_atributo_custom_opciones').value; // Campo de entrada
    const formatedOptions = JSON.parse(inputField).join('\n');
    document.querySelector('#tipo_atributo_custom_opciones').textContent = formatedOptions;
  }
  valueType.children().first().attr('disabled', 'true');
  entityType.children().first().attr('disabled', 'true');
  if (valueType.val()) {
    mandatoryInputsDiv.attr('style', 'display: block');
    if (mandatoryValue.is(':checked')) {
      valueDiv.attr('style', 'display: block');
    }
  }
  mandatoryValue.on('click', function () {
    valueDiv.toggle(this.checked);
    this.checked ? requireDefaultValue() : doNotRequireDefaultValue();
    if (this.checked && valueType.val() === 'lista') {
      valueSelectDiv.toggle(this.checked);
    }
    else if (!this.checked) {
      valueSelectDiv.toggle(this.checked);
      valueDiv.toggle(this.checked);
    }
  });

  optionsSelect.on('change', function () {
    const options = optionsSelect.val().split('\n').filter(item => item);
    valueSelect.html('');
    options.forEach(option => valueSelect.append(new Option(option, option)));
  });

  valueType.on('change', function (e) {
    mandatoryValueCheck() ? requireDefaultValue() : doNotRequireDefaultValue();
    if (e.target.value !== 'lista') { hideValueSelectDiv(); }
    if (e.target.value !== 'fecha') { reloadValue(); }
    if (e.target.value !== 'archivo') {
      showMandatoryInput();
      toUnlockSelectSizeOptions(selectInputSize);
    }
    switch (e.target.value) {
      case 'numerico':
        valueInput().attr('type', 'number');
        valueInput().attr('autocomplete', 'on');
        showValueDiv();
        break;
      case 'string':
        valueInput().attr('type', 'text');
        valueInput().attr('autocomplete', 'on');
        showValueDiv();
        break;
      case 'fecha':
        valueInput().attr('data-provide', 'datepicker');
        valueInput().attr('type', 'text');
        valueInput().attr('autocomplete', 'off');
        showValueDiv();
        break;
      case 'lista':
        valueInput().attr('type', 'text');
        valueInput().attr('autocomplete', 'on');
        showValueDiv();
        break;
      case 'archivo':
        toLockSelectSizeOptions(selectInputSize);
        break;
    }
  });
  function hideValueSelectDiv() { valueSelectDiv.attr('style', 'display: none'); }
  function mandatoryValueCheck() { return mandatoryValue.is(':checked'); }
  function valueInput() { return $('#tipo_atributo_custom_valor_por_defecto'); }
  function showMandatoryInput() { mandatoryInputsDiv.attr('style', 'display: block'); }
  function showValueDiv() {
    if (mandatoryValueCheck()) {
      valueDiv.attr('style', 'display: block');
      hideValueSelectDiv();
    }
  }
  function reloadValue() {
    valueInput().removeAttr('data-provide');
    const html = $('#default-value-div .input-group').html();
    $('#default-value-div .input-group').html(html);
  }
  function requireDefaultValue() {
    if (valueType.val() === 'lista') {
      valueSelect.attr('required', 'true');
      valueInput().removeAttr('required');
    }
    else {
      valueInput().attr('required', 'true');
      valueSelect.removeAttr('required');
    }
  }
  function doNotRequireDefaultValue() {
    valueInput().removeAttr('required');
    valueSelect.removeAttr('required');
  }

  $('.dataTable')
    .delegate(
      'div.editable-input input, div.editable-input select',
      'focus',
      function () {
        const $this = $(this);
        const trParent = $this.parents('tr');
        const tdParent = $this.parents('td');
        const attributeName = tdParent.data('table-attribute');
        if (attributeName === 'input_size') {
          const tipo = $('td[data-table-attribute="tipo_valor"] a', trParent).data('value');
          if (tipo === 'archivo') {
            enableASingleOptionFromASelect($this, InputSizeValues.mediano);
          }
        }
        else if (attributeName === 'position') {
          $this.attr({
            type: 'number',
            min: 0,
          });
        }
      }
    );
});

export {
  InputSizeValues,
  toLockSelectSizeOptions,
  toUnlockSelectSizeOptions,
  enableASingleOptionFromASelect,
  enableAllOptionsFromASelect
};

const alreadyMountedForms = [];
onmount('#custom-attributes-fields-filter-event', function () {
  const formId = $(this).parents('form')[0].id;
  const triggerElement = document.getElementById(formId);
  this.setAttribute('ic-include', formId);
  $(this).on('complete.ic', function () {
    $('#custom-attributes-fields').show();
    $('#buk-loader-intercooler').hide();
  });
  // Llamada al cargar el formulario por primera vez
  Intercooler.triggerRequest($(this));
  if (!alreadyMountedForms.includes(triggerElement)) {
    $(triggerElement).on('change', 'input[type="text"], select, .contract-type-select', (event) => {
      if (!$(event.target).closest('#custom-attributes-fields').length) {
        Intercooler.triggerRequest(this);
      }
    });
    alreadyMountedForms.push(triggerElement);
  }

});

$('#check-filter-attr').on('change', function () {
  updateAmplitude(true);
});

window.changeEntity = function () {
  updateAmplitude(false);
};

function updateAmplitude(check) {
  let jsonString = $('#submit-attr').attr('data-amplitude-tracking');
  const jsonObject = JSON.parse(jsonString);
  jsonObject.data.custom_attribute_entity = $('#tipo_atributo_custom_entidad').val();
  if (check) {
    jsonObject.data.with_filter = $('#check-filter-attr').is(':checked');
  }
  else {
    jsonObject.data.with_filter = $('.filter_checkbox').is(':visible') ? $('#check-filter-attr').is(':checked') : false;
  }
  jsonString = JSON.stringify(jsonObject);
  $('#submit-attr').attr('data-amplitude-tracking', jsonString);
}
