/** @file TODO: documentar */
import Rails from '@rails/ujs';
import onmount from 'onmount';
import { action, actionMessage } from '../../../../../../../app/assets/javascripts/lib/buk/alert';

// Permite desplegar un cuadro de confirmación mediante SweetAlert2
// Modo de Uso:
// <tag data-confirm-swal="Mensaje" />
// o
// <%= data: {confirm_swal: 'Mensaje'} />
// Opcional
// <%= data: {confirm_swal_secondary_message: 'Mensajes secundario a mostrar debajo del principal'} />
// <%= data: {confirm_swal-accept: 'Mensaje del boton aceptar'} /> el que por defecto es Sí
// <%= data: {confirm_swal-decline: 'Mensaje del boton cancelar'} /> el que por defecto es No

const confirmed = (element, result) => {
  if (result.value) {
    // User clicked confirm button
    const message = element.getAttribute('data-confirm-swal');
    element.removeAttribute('data-confirm-swal');
    element.click();
    // emitir este evento de jquery ayuda a integrar
    // intercooler con swal
    $(element).trigger('swal-confirmed');
    element.setAttribute('data-confirm-swal', message);
  }
};

// Display the confirmation dialog
export const showConfirmationDialog = async (element, showConfirmed = true) => {

  const message = element.getAttribute('data-confirm-swal') || element.getAttribute('data-confirm-swal-title');
  const subMessage = element.getAttribute('data-confirm-swal-secondary-message');
  const accept = element.getAttribute('data-confirm-swal-accept') || 'Sí';
  const decline = element.getAttribute('data-confirm-swal-decline') || 'No';
  const icon = element.getAttribute('data-confirm-swal-icon');
  const html = element.getAttribute('data-confirm-swal-html');
  const customAccept = element.getAttribute('data-confirm-swal-custom-accept');
  const swalInput = element.getAttribute('data-swal-input');
  const swalInputValidator = element.getAttribute('data-swal-input-validator');
  const swalInputValidationMessage = element.getAttribute('data-swal-input-validation-message');
  const swalInputAttributes = {
    input: element.getAttribute('data-swal-input-type') || 'text',
    inputPlaceholder: element.getAttribute('data-swal-input-placeholder') || '',
    inputValidator: (swalInputValidator && swalInputValidationMessage) ? (value) => {
      if (value.localeCompare(swalInputValidator, undefined, { sensitivity: 'base' }) !== 0) {
        return swalInputValidationMessage;
      }
    } : null,
  };

  let swalResult = null;
  if (subMessage) {
    // Se muestra mensaje primario y secundario
    swalResult = await actionMessage(
      message, subMessage, accept, decline, icon, customAccept, swalInput, swalInputAttributes);
  }
  else {
    // Se muestra solo un mensaje primario
    swalResult = await action(message, accept, decline, icon, html, customAccept, swalInput, swalInputAttributes);
  }

  if (showConfirmed) confirmed(element, swalResult);
  return swalResult;
};

const allowAction = (element) => {
  if (element.getAttribute('data-confirm-swal') === null) {
    return true;
  }

  showConfirmationDialog(element);
  return false;
};

function handleConfirm(e) {
  if (!allowAction(e.currentTarget)) {
    Rails.stopEverything(e.originalEvent);
  }
}

onmount('a[data-confirm-swal], input[data-confirm-swal], button[data-confirm-swal]', function () {
  $(this).on('click', function (e) {
    if (!e.currentTarget.getAttribute('data-confirm-swal-disable')) {
      handleConfirm(e);

      // TODO: Quitar esta lógica cuando se active el FF habilitar_mejoras_en_formularios_simples
      if (e.currentTarget.hasAttribute('data-validate-new-attempt')) {
        const form = this.closest('form#new_attempt');

        if (form) {
          form.reportValidity();
        }
      }
    }
  });
});

function confirmedPersonalized(result, button) {
  if (result.value) {
    button.removeAttr('data');
    $(button).trigger('click');
  }
}

/** permite generar un alert, utilizando un html para el contenido. ej permite usar iconos font awesome. */
export function personalizedAlert(message, accept, decline, icon, html, e, button, customAccept) {
  if (button.attr('data') === 'active') {
    Rails.stopEverything(e.originalEvent);
    action(message, accept, decline, icon, html, customAccept).then(result => confirmedPersonalized(result, button));
  }
}
