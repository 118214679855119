/**
 * @file Inicializa el Widget de Zendesk dependiendo del país, maneja la creación y/o actualización de
 * usuarios a través de la API y asegura que el widget permanezca activo a través de la navegación de
 * la aplicación con Turbolinks.
 */

function getCacheKey(email) {
  return `zendesk_last_sync_${email}`;
}

// Usamos un caché de 1 hora (60 * 60 * 1000 ms) cuando se abre el widget para evitar
// que se sincronice el usuario demasiadas veces.
const WIDGET_OPEN_SYNC_INTERVAL = 60 * 60 * 1000;

function authenticateZendeskUser(jwt, conversationFields) {
  window.zE('messenger', 'loginUser', callback => callback(jwt));
  if (conversationFields) {
    window.zE('messenger:set', 'conversationFields', conversationFields);
  }
}

async function syncZendeskUser(zendeskSyncUserUrl, email) {
  try {
    const response = await window.jQuery.ajax({
      url: zendeskSyncUserUrl,
      method: 'POST',
      contentType: 'application/json',
      data: JSON.stringify({ email }),
    });

    localStorage.setItem(getCacheKey(email), JSON.stringify({
      timestamp: Date.now(),
      email,
    }));

    return response;
  }
  catch (error) {
    // Si por alguna razón falla la sincronización, no queremos que se detenga la carga del widget
    // eslint-disable-next-line no-console
    console.error('Error syncing Zendesk user:', error);
    return null;
  }
}

function shouldSyncUser(email) {
  const lastSync = localStorage.getItem(getCacheKey(email));
  if (!lastSync) return true;

  const { timestamp, email: lastEmail } = JSON.parse(lastSync);
  const elapsed = Date.now() - timestamp;

  if (email !== lastEmail) return true;
  if (elapsed >= WIDGET_OPEN_SYNC_INTERVAL) return true;

  return false;
}

document.addEventListener('turbolinks:load', async function () {
  // Solo inicializamos si el widget está habilitado
  if (document.body.dataset.enableZendesk !== 'true') return;

  const widgetInfoElement = document.getElementById('zendesk-widget-user-info');
  if (!widgetInfoElement) return;

  const rawData = widgetInfoElement.innerText?.trim();
  if (!rawData) return;

  const zendeskData = JSON.parse(rawData);
  if (!zendeskData) return;

  const { jwt, userData, zendeskSyncUserUrl, widgetScriptSrc } = zendeskData;
  const widgetScriptId = 'ze-snippet';

  if (!document.getElementById(widgetScriptId)) {
    const script = document.createElement('script');
    script.id = widgetScriptId;
    script.async = true;
    script.src = widgetScriptSrc;
    script.onload = () => {
      window.zE('messenger:on', 'open', async function () {
        authenticateZendeskUser(jwt, userData?.conversationFields);

        if (shouldSyncUser(userData.email)) {
          await syncZendeskUser(zendeskSyncUserUrl, userData.email);
        }
      });

      // Dispara un evento personalizado para inicializar el launcher
      document.dispatchEvent(new CustomEvent('zendesk:ready'));
    };
    document.body.appendChild(script);
  }
});

document.addEventListener('turbolinks:before-render', function () {
  delete window.zEACLoaded;
});
