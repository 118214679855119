/** @file TODO: documentar */
import onmount, { $ } from 'onmount';
import * as notify from '../../../../../../../app/assets/javascripts/lib/buk/notice';
import { action, actionMessage } from '../../../../../../../app/assets/javascripts/lib/buk/alert';
import ModalBuilder from '../../../../../../../app/assets/javascripts/lib/modal-builder';

const isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|Windows Phone/i.test(navigator.userAgent);

/**
   * Función para limitar la cantidad de preguntas que se pueden añadir
   * al realizar una publicación de una oferta de trabajo en trabajando.com
   * de un proceso de seleción
   */
onmount('#trabajando_questions', function () {
  const $self = $(this);
  const mutationObserver = new MutationObserver((mutationRecords) => {
    mutationRecords.forEach((mutationRecord) => {
      if (mutationRecord.type === 'childList') {
        var size = $self.children().length;
        if (size === 5) {
          $('#add_trabajando_question > a').hide();
        }
        else if (size < 5) {
          $('#add_trabajando_question > a').show();
        }
      }
    });
  });
  mutationObserver.observe($self.get(0), { childList: true });
});

/**
   * Desabilita/abilita botón de guardado de una regla de automatización
   * si no se han completado los campos "asunto" o "cuerpo del mesaje"
   */
onmount('#notification-action', async function () {
  const { loadCKeditor, CKeditorVersion } = await import('@assets/javascripts/components/ckeditor/load');
  if (CKeditorVersion() === '4') await loadCKeditor();
  // Obtener botón de guardado del formulario
  const $button = $('[type=submit]');
  const $subjet = $('.actions_notification_subject > div > input');
  const $receiverKind = $('.actions_notification_receiver_kind > div > select');
  const $address = $('.actions_notification_address > div > input');

  const $body = CKeditorVersion() === '4' ? CKEDITOR.instances[$('textarea').attr('id')] : $('textarea');

  function validateSubmitButton() {
    let text;
    if (CKeditorVersion() === '4') {
      text = $body.getData();
    }
    else {
      if ($body.length > 1) text = $body[1].value;
      else text = ($body.val() === '' || $body.val() === '<head></head><body></body>') ? $body.text() : $body.val();
    }

    const emptyBody = text === '';
    const emptySubjet = $subjet.val() === '';
    const emptyAddres = $receiverKind.val() === 'address' && $address.val() === '';
    $button.prop('disabled', emptyBody || emptySubjet || emptyAddres);
  }

  // Desabilitar el botón si no se ha completado el campo "asunto" o "Cuerpo del mensaje"
  validateSubmitButton();

  // Habilitar guardado si asusto y cuerpo del mensaje tienen valores
  $body.on('change', function () {
    validateSubmitButton();
  });
  $address.add($subjet).add($receiverKind).on('keyup change', function () {
    validateSubmitButton();
  });
});

onmount('#lista_etapas[data-sortable="true"]', async function () {
  var listaEtapas = this;
  var $listaEtapas = $(listaEtapas);

  var dataText = this.getAttribute('data-text');
  dataText = JSON.parse(dataText);

  function put(url, params) {
    return $.ajax({
      method: 'PUT',
      dataType: 'json',
      url: url,
      data: params,
    });
  }

  function newEmployeeProcess(evt, modalId, modalUrl, params, url) {
    const $self = $(evt.item);
    const nextProcessModal = $(`#${modalId}`);
    const modalParams = {
      seleccion_id: params['seleccion_id'],
      applicant_id: params['applicant_id'],
    };
    const modalTitle = $self.data('modalTitle');

    // Muestra modal
    nextProcessModal.modal('show');

    // Rellena el modal
    nextProcessModal.find('.modal-title').html(modalTitle);
    $.get(modalUrl, modalParams, function (html) {
      nextProcessModal.find('.modal-body').html(html);
    });

    // Evento para devolver los postulantes a sus listas originales
    nextProcessModal.on('click', '.footer-actions a.btn-secondary, .close', function () {
      evt.from.appendChild($self[0]);
    });

    // TODO: Move this to ruby
    if(url.includes('move')) {
      // Evento para guardar el postulante en su nueva posición sin iniciar workflow
      nextProcessModal.on('click', '.skip-workflow', function () {
        put(url, params).fail(function () {
          notify.error(dataText.error_when_moving_applicant);
        }).done(function () {
          // Mostrar botón dentro del cell de postulante en etapa
          const canStartWorkflow = Boolean($self.data('can-start-workflow'));
          if (canStartWorkflow) {
            $self.find('.workflow-box-button').show();
          }
          updateStageCount(evt.from, evt.to);
        });
      });
    }

    nextProcessModal.on('hidden.bs.modal', function () {
      nextProcessModal.find('.modal-body').html('<div class="text-center"><div class="buk-loader"></div></div>');
      nextProcessModal.off();
    });
  }

  function endSelectionProcess(evt, modalId, modalUrl, params, url) {
    newEmployeeProcess(evt, modalId, modalUrl, params, url);
  }

  function alertVacancies(evt, modalId, modalUrl, params, url, internalPostulant = false) {
    const $self = $(evt.item);
    let tipoFlujo = internalPostulant ? 'movimiento' : 'alta';

    if ($listaEtapas.data('onboarding') && !internalPostulant) {
      tipoFlujo = 'onboarding';
    }
    actionMessage(
      dataText.limit_of_vacancies_completed,
      `${dataText.hired_stage_excess_applicants_part_one} ` +
      `${tipoFlujo} ` +
      `${dataText.hired_stage_excess_applicants_part_two}`,
      dataText.start,
      dataText.not_for_the_moment
    ).then(function (res) {
      if (res.value) {
        endSelectionProcess(evt, modalId, modalUrl, params, url);
      }
      else {
        // Devolver la tarjeta del postulante a su posición anterior
        evt.from.appendChild($self[0]);
      }
    });
  }

  /*
    Función utilizada para actualizar los conteos de los titulos al mover entre etapas
    Recibe los nodos DOM que representa de el contenedor de donde viene y donde va la tarjeta del postulante.
  */
  function updateStageCount(from, to) {
    const stages = [$(from).closest('.etapa'), $(to).closest('.etapa')];

    stages.forEach(function ($stage) {
      const count = $stage.find('.contained').find('.applicant-in-stage').length;
      $stage.find('.process-stage-title-count').html(`(${count})`);
      updateDiscardedCount($stage);
    });
  }

  /*
    Función utilizada para actualizar los títulos y la cuenta de cada sección de descarte por etapa.
    Es llamada por cada actualización de etapa, recibiendo esta como parámetro.
  */
  function updateDiscardedCount(stage) {
    const count = stage.find('.discarded-applicant li').length;
    const newText = `Descartados (${count})`;
    stage.find('.process-stage-discarded-title-count').html(newText);
  }

  const { default: Sortable } = await import('sortablejs');

  Sortable.create(listaEtapas, {
    handle: '.sortable',
    animation: 150,
    onUpdate: function (e) {
      if (e.newIndex === 0 && !e.item.className.includes('applicants_column')) {
        var oldIndex = e.oldIndex;
        e.from.insertBefore(e.item, e.from.children[oldIndex + 1]);
        return;
      }
      var etapas = $('#lista_etapas .etapa'),
        orden = etapas.map(function () { return $(this).data('id'); }).toArray(),
        url = $listaEtapas.data('sort-url');

      put(url, { orden: orden })
        .fail(function () {
          notify.error(dataText.error_storing_order);
        });
    },
  });
  $(listaEtapas).find('.postulantes').each(function () {
    Sortable.create(this, {
      group: 'proceso',
      animation: 150,
      swapThreshold: 1,
      sort: true,
      delay: isMobile ? 500 : 0,
      onAdd: function (evt) {
        // no hacer nada si no cambio de lista
        if (evt.to === evt.from) {
          return;
        }
        var $postulantes = $(evt.to.children),
          orderIds = $postulantes.map(function () { return $(this).data('application-id'); }).toArray(),
          $target = $(evt.to),
          $self = $(evt.item),
          url = $self.data('url'),
          params = {
            seleccion_id: $listaEtapas.data('id'),
            etapa_id: $target.data('id'),
            status: $target.data('status'),
            applicant_id: $self.data('applicant-id'),
            order: orderIds,
          };

        if ($target.data('isFinalStep')) {
          const internalPostulant = Boolean($self.data('internal-postulant'));
          const modalUrl = $self.data('modalUrl');
          const modalId = $self.data('modalId');
          const hiredInStartedProcess = Boolean($self.data('hiredInStartedProcess'));
          const vacancies = parseInt($target.data('vacancies'));
          const reachedVacanciesLimit = $target.children().length > vacancies;

          if (hiredInStartedProcess) {
            action(dataText.applicant_already_hired, dataText.accept)
              .then(function () {
                evt.from.appendChild($self[0]);
              });
          }
          else if (reachedVacanciesLimit) {
            // Se revisa si la cantidad de postulantes en la etapa supera las vacantes
            // Si excede las vacantes pregunta si se desea consitnuar con sweetAlert y luego el modal
            // Caso contrario sigue con el modal
            alertVacancies(evt, modalId, modalUrl, params, url, internalPostulant);
          }
          else {
            endSelectionProcess(evt, modalId, modalUrl, params, url);
          }
        }
        else {
          /**
            * Maneja el movimiento de postulantes entre etapas.
            * Se activa cuando el usuario arrastra un postulante de una etapa a otra.
            * o cuando lo mueve dentro de la misma etapa.
          */
          put(url, params)
            .fail(function () {
              notify.error(dataText.error_when_moving_applicant);
            })
            .done(function () {
              // Se oculta el botón de iniciar workflow
              updateStageCount(evt.from, evt.to);
              $self.find('.workflow-box-button').hide();
              notify.clear();
              notify.notice(dataText.applicant_successfully_passed_stage);
            });
        }

        evt.to;    // target list
        evt.from;  // previous list
        evt.oldIndex;  // element's old index within old parent
        evt.newIndex;  // element's new index within new parent
      },
      // ordenar postulantes dentro de la etapa
      onUpdate: function (evt) {
        var $postulantes = $(evt.from.children),
          $self = $(evt.item),
          etapaId = $(evt.target).data('id'),
          url = $self.data('sort-url'),
          orderIds = $postulantes.map(function () { return $(this).data('application-id'); }).toArray();
        put(url, { order: orderIds, etapa_id: etapaId })
          .fail(function () {
            notify.clear();
            notify.error(dataText.error_storing_order);
          });
      },
    });
  });
});

$(document).on('click', '.final-step-checkbox', function () {
  $('.final-step-checkbox').not(this).prop('checked', false).val(0).attr('disabled', false);

  const box = $(this).parents('.box .box-inner');
  $('.box').removeClass('box-outer');

  const formId = $(this).parents('form').attr('id');

  if ($(this).prop('checked') === true) {
    $(this).val(1);
    box.addClass('box-outer');
    $(`#${formId}`).submit();
    $(this).attr('disabled', true);
  }
});

/*
 * Función para cambiar de color todas las estrellas dejandolas en el estado correspondiente
 * al puntaje de la estrella seleccionada
 *
 * @param {HTMLElement} starObj - Objeto html correspondiente a la estrella del puntaje seleccionado.
 * @param {number} [currentScore=0] - Puntaje actual a comparar. Si no se proporciona, se establece en 0 por defecto.
 */

function colorStars(starObj, currentScore = 0) {
  const star = 'star';
  const parent = $(starObj).parents('[data-score]');
  const starValue = $(starObj).index() + 1;
  parent.find('.material-icons-outlined').text('star').each(function (_) {
    const nodePosition = $(this).index() + 1;
    if (nodePosition <= starValue && starValue !== currentScore) {
      $(this).text(star).addClass('text-secondary-400').removeClass('text-basic-500');
    }
    else {
      $(this).text(star).addClass('text-basic-500').removeClass('text-secondary-400');
    }
  });
}

// Encargado de pintar las estrellas seleccionadas y refrescar el valor del hidden field "score"
onmount('[data-score-star]', function () {
  $(this).bind('scored-event');

  if ($(this).attr('data-star-in-modal')) {
    $('#evaluation-stars').show();
  }
  else {
    $('#evaluation-stars').hide();
  }

  var triggerScoredEvent = $(this).data('trigger-score');
  $(this).find('.material-icons-outlined').text('star').on('click', function (evt) {
    const $star = $(this);
    const $input = $star.parents('[data-score]').closest('div').find('#note_score');
    const currentScore = parseInt($input.val(), 10) || 0;
    const selectedScore = $star.index() + 1;
    const newScore = selectedScore === currentScore ? '' : selectedScore;

    colorStars($star, currentScore);
    $input.val(newScore);
    $('.star_value').text(newScore);

    evt.preventDefault();
    if (triggerScoredEvent) {
      $(this).trigger('scored-event');
    }
    return false;
  });
});

onmount('[data-score-update-url]', function () {
  $(this).find('.material-icons-outlined').text('star').on('click', function (evt) {
    const $star = $(this);
    const $parent = $(this).parent();
    const $originalScore = parseInt($parent.data('score'));
    const $originalStar = $parent.children().eq($originalScore - 1);
    const id = $star.parent('[data-score]').data('score-id');
    const dataUrl = $star.parent('[data-score]').data('score-update-url');

    // Retornar y no modificar estrellas ni puntaje si no se tiene la url (sin permisos de escritura)
    if (!dataUrl.length) return;

    /**
     * Actualiza los atributos usados para el ordenamiento y busqueda de la columna de puntaje
     * en datatables
     * @param {JQuery} dataCellElement contenedor td del componente de estrellas
     */
    function updateRowOrder(dataCellElement, newScore) {

      const $table = dataCellElement.closest('table').DataTable();
      const $row = $table.row(dataCellElement.closest('tr'));
      const data = $row.data();

      data.score.display = dataCellElement.html();
      data.score.value = newScore;
      $row.data(data).draw();
    }

    colorStars($star);
    const newScore = $star.index() + 1;

    $.ajax({
      method: 'PATCH',
      dataType: 'json',
      url: dataUrl,
      data: {
        postulacion: {
          score: newScore,
        },
      },
    }).then(function (response) {
      if (response.errors) {
        colorStars($originalStar);
      }
      else {
        $parent.attr('data-score', newScore);
        // Si se marca en la vista de detalles lateral, se debe actualizar la tarjeta en la etapa
        if ($parent.hasClass('applicant-aside-score')) {
          const $stageStarParent = $(document)
            .find(`.applicant-in-stage [data-score-id="${id}"],.applicant-in-stage-table [data-score-id="${id}"]`);
          const $stageStar = $stageStarParent.children().eq(newScore - 1);
          colorStars($stageStar);
          $stageStarParent.attr('data-score', newScore);

          // Ajustar el puntaje para ordenamiento si es tabla
          if ($stageStar.closest('#applicants_in_stage_table').length) {
            updateRowOrder($stageStar.closest('td'), newScore);
          }
        }
        else {
          // Ajustar el puntaje para ordenamiento si es tabla
          if ($star.closest('#applicants_in_stage_table').length) {
            updateRowOrder($star.closest('td'), newScore);
          }
        }
      }
    }).fail(function () {
      colorStars($originalStar);
    });

    evt.preventDefault();
    return false;
  });
});

/*
  Se utiliza para evitar que al hacer click en el checkbox de un postulante se gatille la vista de detalles.
*/
onmount('.applicant-massive-action-check', function () {
  $(this).on('click', function (e) {
    e.stopPropagation();
  });
});

/*
  Evento que especial para mostrar el botón de inciar workflow sin hacer conflicto con el aside de postulantes.
  Basicamente el mismo evento que se ve en modal-link, pero adaptado para no propagarse una vez se inicia este
  y no gatillar el aside.
*/
onmount('[data-applicant-in-stage-workflow-button]', function () {
  $(this).on('click', function (ev) {
    var $self = $(this);
    var url = $self.attr('href');
    var title = $self.attr('title') || $self.data('modalTitle');
    var id = $self.data('id');

    var size = $self.data('modalSize');
    var footer = $self.data('modalFooter');

    var modalBuilder = new ModalBuilder();
    if (!footer) { modalBuilder.setFooter(''); }

    modalBuilder.setId(id);
    modalBuilder.setSize(size).show().loadFromRemote(url, {}, title);
    modalBuilder.getModal().on('modal:reload', () => modalBuilder.loadFromRemote(url, {}, title));

    ev.preventDefault(); ev.stopPropagation();
    return false;
  });
});

onmount('#jefes_select_box', function () {
  function refreshSupervisorSelect(areaId, roleId, disabled, required, formName) {
    // Si el select es de solo lectura no es necesario hacer el refresh, solo mostrar la selección.
    if (disabled === 'true') { return; }

    const $target = $('#jefes_select_box');

    // (parseInt(val) || null) retorna un entero si val es parseable a entero o null (en vez de NaN)
    areaId = parseInt(areaId) || null;
    roleId = parseInt(roleId) || null;
    const oldAreaId = $target.data('area-id') || null; // Convertir a null si son undefined
    const oldRoleId = $target.data('role-id') || null;

    /*
      Si el areaId y roleId escritos en el contenedor corresponden a los mismos actuales, no se actualiza.
      Esto es para evitar que se llame multiples veces al controlador cuando el onchange de area gatilla
      onchange de cargos en algunas ocasiones el cual repite la misma area y rol.
    */
    if (oldAreaId === areaId && oldRoleId === roleId) return;

    let url = '/seleccions/jefes_select?';
    if (areaId) url = url.concat(`area_id=${areaId}`);
    if (roleId) url = url.concat(`&role_id=${roleId}`);
    if (disabled) url = url.concat(`&disabled=${disabled}`);
    if (required) url = url.concat(`&required=${required}`);
    if (formName) url = url.concat(`&form_name=${formName}`);

    fetch(url, { credentials: 'same-origin' }).then((res) => res.text())
      .then((html) => {
        $target.children().replaceWith(html);
        $target.data('area-id', areaId);
        $target.data('role-id', roleId);
        $.onmount(); // Se gatilla nuevamente onmount para refrescar Select2
      });
  }

  $(document).on('change', '#seleccion_role_id, #seleccion_operation_role_id', function (e) {
    const disabled = $('#seleccion_supervisor_id').attr('data-disabled');
    const required = $('#seleccion_supervisor_id').attr('data-required');
    const formName = $('#seleccion_supervisor_id').attr('data-form-name');
    const areaId = $('#area_id').val();
    const roleId = $(e.target).val();
    refreshSupervisorSelect(areaId, roleId, disabled, required, formName);
  });

  $(document).on('change', '#area_id', function () {
    const disabled = $('#seleccion_supervisor_id').attr('data-disabled');
    const required = $('#seleccion_supervisor_id').attr('data-required');
    const formName = $('#seleccion_supervisor_id').attr('data-form-name');
    const areaId = $('#area_id').val();
    refreshSupervisorSelect(areaId, null, disabled, required, formName);
  });

});

/*
  Obtiene la empresa, área y cargo del empleado sustituto seleccionado y autocompleta esos campos.

  - Si el empleado no tiene trabajo se resetean los selectores de empresa y cargo. El campo de área
    no se resetea aquí porque se hace automáticamente al dispararse el treeselect de área.

  - Funciona para procesos de selección y para workflow de búsqueda.
*/
onmount('[data-substitute-employee]', function () {
  const $self = $(this);
  const url = $self.data('substituteEmployee');
  $self.on('change', function () {
    var employeeId = $self.val();
    if (!employeeId) {
      return;
    }
    $.ajax({
      method: 'GET',
      dataType: 'json',
      url: url,
      data: {
        employeeId: employeeId,
      },
    }).then(function (data, textStatus) {
      var formName = $self.attr('data-form-name');
      var company = $('#' + formName + '_empresa_id');
      var role = $('#' + formName + '_role_id');

      if (textStatus === 'nocontent') {
        company.val(null).trigger('change');
        role.val(null).trigger('change');
        return;
      }

      var roleOption = new Option(data['role_name'], data['role_id'], true, true);
      company.val(data['company_id']).trigger('change');
      role.parent().parent().show();
      role.append(roleOption).trigger('change');
      $('#area_id').trigger('change');
    });
  });
});

/*
  valida la carga del CV del postulante para que sea del formato esperado.
  Esto se ubica en el formulario de postulación.
*/
$(document).on('change', '#public_url-cv_uploader', function () {
  const $self = $(this);
  var docType = this.files[0].type;
  if (!['application/pdf', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    'application/msword'].includes(docType)) {
    actionMessage('Solo se permiten curriculums en formato PDF, DOC o DOCX.');
    $self.val('');
  }
});

$(document).on('change', '#edit_company_logo_url', function () {
  var doc = this.files[0];
  if (doc) {
    const $card = $(this).parents('.card-settings');
    const $btn = $card.find('#btn_upload_company_logo');
    $btn.text(doc.name);
  }
});

onmount('[data-stage-select]', function () {
  $(this).on('change', function (e) {
    window.location.href = e.target.getAttribute('data-stage-select') + `&current_stage_id=${e.target.value}`;
  });
});

/*
  Eventos del contenedor de postulantes descartados en cada etapa
*/
onmount('.etapa .discarted', function () {
  const $discartedContainer = $(this);

  /*
    Se utiliza para mostrar una flecha hacia arriba o abajo según si se despliegan los descartados o están
    colapsados. Se usa este para usar una flecha distinta a la usada en otros colapsables.
  */
  $discartedContainer.on('click', '[data-toggle="collapse"]', function () {
    const $targetArrow = $discartedContainer.find('.fa[class*="fa-chevron-"]');
    $targetArrow.toggleClass('fa-chevron-up fa-caret-down');
  });

  /*
    Al hacer click en el select-all de postulantes descartados, si la etapa donde se encuentran estos no
    está abierta, se abre automáticamente.
  */
  $discartedContainer.find('[data-action="select_etapa_discarted"]').on('click', function () {
    const $discartedToggle = $discartedContainer.find('.discarted-applicants-toggle #filters-toggle');
    if ($discartedToggle.length >= 1 && $discartedToggle.hasClass('collapsed')) {
      $discartedToggle.trigger('click'); // Gatilla el metodo de abrir la sección
    }
  });

});

/*
  Muestra un tooltip cuando el texto contenido se encuentra en overflow (excede el contenedor)
  data-overflow-tooltip: Texto a mostrar en el tooltip cuando se encuentre en overflow (cortado)
*/
onmount('[data-overflow-tooltip]', function () {
  const overflowText = $(this).data('overflow-tooltip');

  $(this).on('mouseover', function (e) {
    if (e.target.clientWidth < e.target.scrollWidth) {
      $(e.target).tooltip({ container: 'body', boundary: 'window', title: overflowText });
      $(e.target).tooltip('show');
    }
  });

  $(this).on('mouseout', function (e) {
    // Si se usa hide, el tooltip se vuelve a mostrar aunque el texto ya no exceda el contenedor
    $(e.target).tooltip('hide');
  });
});

/*
  Vista de etapas de un proceso de selección
  Monta el evento para reconocer cuando se está arrastrando un postulante a la zona de descartados, para
  abrir esta.
*/
onmount('.selection-process-stage-dragover', function () {
  $(this).on('dragenter', function (e) {
    const $discartedToggle = $(e.target).find('#filters-toggle');

    if ($discartedToggle.hasClass('collapsed')) {
      $discartedToggle.trigger('click'); // Gatilla el metodo de abrir la sección
    }

  });
});

onmount('.recruiting-survey-time-remaining', async function () {
  const { default: moment } = await import('moment');
  const $main = $(this);
  var timeRemaining = document.getElementById('time_remaining').value;
  var countDownDate = moment(timeRemaining).valueOf();
  var myVar = setInterval(myTimer, 1000);
  var dataText = this.getAttribute('data-text');
  dataText = JSON.parse(dataText);

  function myTimer() {
    try {
      var now = moment().valueOf();
      var distance = countDownDate - now;
      var hours = ('0' + Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60))).slice(-2);
      var displayTime = moment(distance).format(':mm:ss');
      document.getElementById('time_remaining_display')
        .innerHTML = dataText.time_remaining_to_respond + (hours + displayTime).bold();
      if (distance < 0) {
        document.getElementById('time_remaining_display')
          .innerHTML = dataText.time_remaining_to_respond + (dataText.time_is_up).bold();
        const $boxBody = $main.closest('.box-body');
        const $buttonSaveAnswers = $boxBody.find('#attempt_send_answers');
        $buttonSaveAnswers.removeAttr('data-confirm-swal');
        $buttonSaveAnswers.trigger('click');
      }
    }
    catch (e) {
      clearInterval(myVar);
    }
  }
});

/*
  Vista de revisión de formularios de selección por medio del paginador.
  Al llegar a la última pagina del paginador cambia el texto del botón.
*/
onmount('#btn-save-score button', function () {
  const $self = $(this);
  const $btnLabel = $self.children('.btn-label');
  const $btnImage = $self.children('i');
  const $main = $self.parents('#by-applications-review, #by-questions-review');
  const $paginator = $main.find('.paginator-component');
  const $isPaginatorLastPage = $main.find('#paginator_last_page');
  var dataText = $self.parent().attr('data-text');
  dataText = JSON.parse(dataText);

  // Si el paginador tiene solo 1 elemento para mostrar, cambiar el boton por guardar y salir.
  if ($paginator.data('current-position') === $paginator.data('stop-position')) {
    $btnImage.removeClass('fa-save').addClass('fa-arrow-right');
    $btnLabel.text(dataText.exit);
    $isPaginatorLastPage.val(true);
  }
  // maneja los 3 eventos del paginador (inicio, ...., fin)
  $main.on('paginator.lastPage', function () {
    $btnImage.removeClass('fa-save').addClass('fa-arrow-right');
    $btnLabel.text(dataText.exit);
    $isPaginatorLastPage.val(true);
  });
  $main.on('paginator.firstPage paginator.middlePage', function () {
    $btnImage.removeClass('fa-save').addClass('fa-arrow-right');
    $btnLabel.text(dataText.follow);
    $isPaginatorLastPage.val(false);
  });
  $(document).on('change', '.answers_score input', function () {
    var label = $btnLabel.text();
    if (label.split(' ').length === 1) {
      $btnLabel.text(dataText.save_and + label);
    }
    $btnImage.addClass('fa-save').removeClass('fa-arrow-right');
  });
});

onmount('.recruiting-advanced-filter', function () {
  if ($(this).is(':visible')) {
    const url = new URL(window.location.href);
    const params = new URLSearchParams(url.search);
    // Removemos búsqueda y asignamos fragmento de la uri con el parámetro type_tab
    url.hash = params.get('type_tab');
    url.search = '';
    window.history.pushState({}, document.title, `${url.href}`);
  }
});

/* Esconde la cabecera del modal en las previsualización de un formulario de selección */
onmount('#application_template_form_preview', function () {
  const $modalContent = $(this).parents('.modal-content');
  const $modalheader = $modalContent.children('.modal-header');
  const $modalBody = $modalContent.children('.modal-body');
  $modalBody.addClass('px-0');
  $modalBody.addClass('py-0');
  $modalheader.addClass('hidden');
});

/*
* En la vista de documentos de un postulante, al minimizar el modal de subida de archivos permite
* gatillar un evento que es manejado con intercooler(ic-trigger-on) que permite refrescar el listado de documentos
*/
onmount('#upload_files_modal', function () {
  $('#documents_container').bind('upload-files-dismiss');
  $(this).on('hide.bs.modal', function () {
    $('#documents_container').trigger('upload-files-dismiss');
  });
});

/* Vista de etapas de un proceso de selección.
   Mostrar alerta en modal de asignación de formulario de preguntas
   en caso que se elija un formulario de pregunta que ya ha sido
   enviado a alguno de los postulantes marcados. */
onmount('#template_form_assignment_modal', function () {
  const $select = $(this).find('#recruiting_selection_process_survey_id');
  const checkIfShowReassignAlert = () => {
    const applicantsPerSelectionProcessSurvey = $select.data('applicants-per-selection-process-survey');
    const selectedApplicants = $('#assign_applicants').children().toArray().map(el => parseInt(el.value));
    const selectedSelectionProcessSurvey = $select.val();

    for (const applicant of selectedApplicants) {
      if (applicantsPerSelectionProcessSurvey[selectedSelectionProcessSurvey].includes(applicant)) {
        $('#template_form_reassign_alert').show();
        return;
      }
    }
    $('#template_form_reassign_alert').hide();
  };

  /* Verificamos si a alguno de los postulantes marcados ya se les ha enviado el formulario seleccionado.
     En caso positivo se muestra una alerta. Esta verificación se dispara al desplegar modal y también al
     cambiar la opción del selector de formularios de preguntas.*/
  $('#template_form_assignment_modal').on('shown.bs.modal', function () {
    checkIfShowReassignAlert();
  });
  $select.on('change', function () {
    checkIfShowReassignAlert();
  });
});

onmount('#new_recruiting_application_template_form', function () {
  const $submitButton = $(this).find('#form_assignment_button');
  $submitButton.attr('disabled', true);

  $('#source-selector').on('change', function () {
    const text = $('#source-selector option:selected').text();
    if (text === 'Seleccionar fuente...') {
      $submitButton.attr('disabled', true);
      $('#survey-selector-container').hide();
    }
    else {
      $('#survey-selector-container').show();
    }
  });

  const container = $('#survey-selector-container');
  container.on('change', function () {
    const text = $('#survey-selector option:selected').text();
    if (text === 'Seleccionar formulario...') {
      $submitButton.attr('disabled', true);
    }
    else {
      $submitButton.attr('disabled', false);
    }
  });
});

onmount('#postulante-table', async function () {
  const requestTab = $(this);
  const $btn = $(this).find('#applicants_requests_massive_actions');
  const originalButtonText = $btn.text();
  const iconHTML = '<i class="fas fa-arrow-right"></i> '; // Define el HTML del ícono
  $btn.attr('disabled', true);
  const table = requestTab.find('#applicants_table');
  requestTab.on('click change', '.applicants-request-checkbox', function () {
    const applicantsPostulations = table.find('input[type=checkbox].selection:checked').length;
    $btn.attr('disabled', !applicantsPostulations);
    $btn.html(iconHTML + originalButtonText);
  });
});

onmount('#selection_process-assigment-applicants-selector-container', function () {
  const $submitButton = $(this).find('#selection_process_assignment_button');
  $submitButton.attr('disabled', true);
  $(this).on('change', function () {
    const $selectorApplicant = document.getElementsByClassName('select2-selection__choice').length;
    $submitButton.attr('disabled', !$selectorApplicant);
  });
});

onmount('#content_postulant_cv', function () {
  const pdfPreview = $('#content_postulant_cv iframe')[0];
  const userAgent = navigator.userAgent;
  const desktopRegex = /^(?!.*(mobile|tablet)).*$/i;

  if (!desktopRegex.test(userAgent)) {
    pdfPreview.classList.add('d-none');
  }
});

/*
* En el modal ficha postulante al abrir otro modal encima y despues cerrarla
* hace que se pierda el scroll del modal principal.
* Restaurar scroll del modal ficha postulante.
*/
onmount('.restore_ficha_postulante_scroll', function () {
  const btnCloseModal = $(this).parent().find('a[data-dismiss="modal"]');
  btnCloseModal.on('click', function () {
    $('#modal_ficha_postulante').css('overflow', 'scroll');
  });
});

/*
* Eliminar la ficha del postulante despues de redirigir a otra pestaña
*/
onmount('.remove-modal-ficha-postulante', removeModalFichaPostulante);
function removeModalFichaPostulante() {
  $('.remove-modal-ficha-postulante').on('click', function () {
    $('#modal_ficha_postulante').modal('hide');
    $('#modal_ficha_postulante').remove();
    $('.modal-backdrop').remove();
  });
}

function setAddressFieldRequirement(parent) {
  var receiverKind = $(parent).find('span[id*=receiver_kind]').first().text();
  if (receiverKind === 'Dirección de correo') {
    $('input[id*=notification_address]').prop('required', true);
  }
  else {
    $('input[id*=notification_address]').prop('required', false);
  }
}

onmount('#notification-action', function () {
  setAddressFieldRequirement($(this).find('#receiver-kind').first());
  $(this).find('#receiver-kind').on('change', function () {
    setAddressFieldRequirement(this);
  });
});

/* Permite cambiar de pestaña en la ficha del postulante
    al hacer click en "¡Valorar Postulante!" o "¡Ir a evaluar!" dejando
    la pestaña de "Comentarios" o "Scorecard" activa.
*/
onmount('#application_tabs', function () {
  const $tabTitles = $('#application_tabs ul > li > a').map(function () {
    return $(this).text().trim();
  }).get();
  const $tabButtons = $('ul.nav-tabs > li.nav-item').filter(function () {
    return $tabTitles.includes($(this).text().trim());
  });
  const $rateApplicantLink = $('#rate-applicant');
  const $rateScorecardLink = $('#rate-scorecard');
  $rateApplicantLink.on('click', function () {
    $tabButtons.map(function () {
      return $(this).children().removeClass('active');
    });
    $('a[href="#comments-tab"]').addClass('active');
    if ($rateApplicantLink.hasClass('active')) {
      $rateApplicantLink.removeClass('active');
    }
  });
  $rateScorecardLink.on('click', function () {
    $tabButtons.map(function () {
      return $(this).children().removeClass('active');
    });
    $('a[href="#scorecard-tab"]').addClass('active');
    if ($rateScorecardLink.hasClass('active')) {
      $rateScorecardLink.removeClass('active');
    }
  });
});

/*
  Permite realizar una request despues de cargar el paginator
  para obtener las acciones del modal de la ficha del postulante
*/
onmount('#postulante-ficha-cell__paginator', function () {
  let postulacionCurrentValue = 0;
  let acctionRequest = false;
  const elementSelector = $('#application-template-form-paginator .paginator-select');
  const previousButton = $('#application-template-form-paginator .paginator-previous-button button');
  const nextButton = $('#application-template-form-paginator .paginator-next-button button');
  elementSelector.on('change', resyncActions);
  previousButton.on('click', resyncActions);
  nextButton.on('click', resyncActions);
  const failedRequestText = this.getAttribute('data-text');

  elementSelector.on('beforeSend.ic', resyncActions);
  elementSelector.on('complete.ic', function () {
    if(acctionRequest) {
      $('#buk-loader-intercooler').addClass('display-none');
      $(elementSelector).attr('disabled', true);
    }
  });

  async function resyncActions() {
    const postulacionNextValue = elementSelector.val();
    if(postulacionCurrentValue !== postulacionNextValue) {
      postulacionCurrentValue = postulacionNextValue;
      $(elementSelector).attr('disabled', true);
      $(previousButton).addClass('disabled');
      $(nextButton).addClass('disabled');
      $('#ficha_postuante_actions_dropdown').addClass('disabled');
      acctionRequest = true;
      fetch(`/postulacions/${postulacionNextValue}/resync_actions?application_id=${postulacionNextValue}`)
        .then(response => response.text())
        .then(response => $('#postulante-ficha-cell__actions').html(response))
        .catch(() => notify.error(failedRequestText))
        .finally(function () {
          acctionRequest = false;
          removeModalFichaPostulante();
          $(elementSelector).attr('disabled', false);
          $(previousButton).removeClass('disabled');
          $(nextButton).removeClass('disabled');
          $('#ficha_postuante_actions_dropdown').removeClass('disabled');
          $('#buk-loader-intercooler').removeClass('display-none');
        });
    }
  }
});

onmount('#generate-email-button', function () {
  $(this).on('click', function () {
    limpiarSelect();
  });

  function limpiarSelect() {
    var select = document.getElementById('template-selector');
    if (select !== null) {
      select.selectedIndex = 0;
    }
  }
});

/*
* Muestra el detalle (aside bar) de un proceso de selección
* si existe el parámetro seleccion_id en la url.
*/
onmount('#selection_processes_list', function () {
  const params = new URLSearchParams(document.location.search);
  if (params.get('seleccion_id') !== null) {
    const linkId = 'link_selection_process_' + params.get('seleccion_id');
    const linkElement = document.getElementById(linkId);
    linkElement && linkElement.click();
  }
});

/*
* recarga el js que revisa si hay notificaciones de workers que mostrar
* y deshabilita y habilita el botón según aparezcan notificaciones
*/
// Función que se ejecuta al hacer clic en el botón de análisis de CVs
onmount('#bulk_cv_copilot_analysis', function () {
  let intervalSwitch = false;
  $(this).on('click', function () {
    $(document).trigger('worker-status-check');
    this.disabled = true;  // Deshabilita el botón
    startInterval(); // Activa el intervalo
  });
  // Función para verificar periódicamente si un elemento .noty_body está presente
  function startInterval() {
    intervalSwitch = setInterval(enableButtonIfNotyPresent, 500);
  }

  function enableButtonIfNotyPresent() {
    const titleClass = document.querySelector('.noty_content__title h4');
    if (document.querySelector('.noty_type__success') && titleClass && titleClass.textContent.includes('Buk Copilot')) {
      document.getElementById('bulk_cv_copilot_analysis').disabled = false;
      clearInterval(intervalSwitch);
    }
  }
});

/*
 * Esta función se monta en el botón con id 'button-save-template-process'.
 * Su propósito es manejar el evento clic en este botón, mostrando una alerta
 * y realizando acciones basadas en la respuesta del usuario.
 */
onmount('#button-save-template-process', function () {
  const formElement = this.form;
  const dataText = JSON.parse(this.getAttribute('data-text'));

  // Evento clic asociado al botón de guardar plantilla.
  // Previene la acción por defecto del formulario y muestra una alerta personalizada.
  $(this).on('click', function (e) {
    e.preventDefault();

    // Muestra una alerta para confirmar la adición de automatización a la plantilla.
    showAlert(dataText)
      .then((result) => {
        // Si el usuario confirma, añade un input oculto para la automatización.
        if (result.isConfirmed) {
          addHiddenInputForAutomation(formElement);
        }
        // Envía el formulario independientemente de la elección del usuario.
        formElement.submit();
      });
  });
});

/*
 * Muestra una alerta personalizada al usuario.
 * Devuelve una promesa que se resuelve con la respuesta del usuario.
 */
function showAlert(dataText) {
  const options = {
    message: dataText.message_exit,
    confirmButtonText: dataText.confirm_btn,
    cancelButtonText: dataText.cancel_btn,
    icon: 'success',
    html: `<p>${dataText.content}</p>`,
    customConfirmButton: 'btn-custom-confirm',
  };

  return action(options.message,
    options.confirmButtonText,
    options.cancelButtonText,
    options.icon, options.html,
    options.customConfirmButton);
}

/*
 * Añade un input oculto al formulario para indicar la selección de automatización.
 */
function addHiddenInputForAutomation(formElement) {
  $('<input>').attr({
    type: 'hidden',
    name: 'template_automation',
    value: 'true',
  }).appendTo(formElement);
}

/*
 * Actualiza el filtro de estado de la tabla de procesos de selección,
 * esta accion se realiza para que el filtro se aplique al cargar
 * la vista de procesos de selección, ya que el table_widget no permite
 * realizar un pre-filtrado
 */
onmount('#selection_processes_datatable_id', function () {
  const STATUS_COLUMN_INDEX = 1;
  const STARTED_STATE_ID = 1;

  const table = $(this).DataTable();

  const sessionSearchState = table.column(STATUS_COLUMN_INDEX).search();
  if (!sessionSearchState) {
    // Actualizamos el filtro con el estado por defecto
    table.column(STATUS_COLUMN_INDEX).search(STARTED_STATE_ID);
  }
});
